import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  required,
} from 'react-admin';
import getUuid from 'uuid-by-string';
import { S3ImageInput } from './aws/S3ImageInput';

const modules = [
  { name: 'details' },
  { name: 'timer' },
  { name: 'subscribe' },
  { name: 'schedule' },
  { name: 'ended' },
  { name: 'mainStage' },
  {
    name: 'marketplace',
    modules: [{ name: 'featuredProducts' }, { name: 'activitySchedule' }],
  },
  { name: 'brands' },
  { name: 'navigation' },
];
const mapModules = (modules) =>
  modules.map(({ name, modules }, index) => ({
    id: getUuid(name),
    name,
    display: true,
    order: index + 1,
    modules: modules ? mapModules(modules) : undefined,
  }));
const initialValues = {
  modules: mapModules(modules),
};

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect="show" initialValues={initialValues}>
      <TextInput source="organizerId" disabled validate={[required()]} />
      <TextInput source="name" />
      <TextInput source="slug" />
      <TextInput multiline source="details" />
      <S3ImageInput
        source="poster"
        accept="image/*"
        multiple={false}
        keyPrefix="event-poster/"
      />
      <S3ImageInput
        source="banner"
        accept="image/*"
        multiple={false}
        keyPrefix="event-banner/"
      />
      <DateTimeInput source="startingAt" />
      <DateTimeInput source="endingAt" />
      <ArrayInput source="themeProperties">
        <SimpleFormIterator disableAdd disableRemove>
          {/* <TextInput source="id" label="Id" disabled /> */}
          <TextInput source="name" label="Name" disabled />
          <ArrayInput source="themeProperties" label="Modules" >
            <SimpleFormIterator disableAdd disableRemove>
              {/* <TextInput source="id" label="Id" disabled /> */}
              <TextInput source="name" label="Name" disabled />
              <TextInput source="value" label="Value" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="modules">
        <SimpleFormIterator disableAdd disableRemove>
          {/* <TextInput source="id" label="Id" disabled /> */}
          <TextInput source="name" label="Name" disabled />
          <BooleanInput source="display" label="Display" />
          <NumberInput source="order" label="Order" />
          <ArrayInput source="modules" label="Modules" >
            <SimpleFormIterator disableAdd disableRemove>
              {/* <TextInput source="id" label="Id" disabled /> */}
              <TextInput source="name" label="Name" disabled />
              <BooleanInput source="display" label="Display" />
              <NumberInput source="order" label="Order" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
