import React from 'react';

export const S3Image = ({ src, record, source, imgProps }) => {
  const s3Object = (record && source && record[source]) || record;
  return s3Object && src ? (
    <img
      src={src}
      title={s3Object.key}
      style={{ maxWidth: '100%' }}
      alt=""
      {...imgProps}
    />
  ) : null;
};
