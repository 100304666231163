import React from 'react';
import getLiveStreamUrl from '../../../helpers/getLiveStreamUrl';

const CoHostBroadcastLinkField = ({ record = {} }) => (
  <span>
    <a
      href={`${getLiveStreamUrl()}/broadcast/${record.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`${getLiveStreamUrl()}/broadcast/${record.id}`}
    </a>
  </span>
);

CoHostBroadcastLinkField.defaultProps = {
  label: 'Co-host broadcast link',
  addLabel: true,
};

export default CoHostBroadcastLinkField;