import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin';
import { AmplifyPagination } from '../../ra-aws-amplify';
import ListActions from './ListActions';
import SyncButton from './shop/SyncButton';

export default (props) => {
  const [filter, setFilter] = useState(null);

  return (
    <List
      {...props}
      actions={
        <ListActions showCreate={false} customActions={<SyncButton />} />
      }
      pagination={<AmplifyPagination onNext={(filter) => setFilter(filter)} />}
      filter={filter}
      perPage={100}
    >
      <Datagrid rowClick="show">
        <TextField disabled label="Id" source="id" />
        <TextField source="email" />
        <TextField source="handle" />
        <TextField source="name" />
        <BooleanField source="active" />
        <BooleanField source="vacation" />
        <BooleanField source="approved" />
        <BooleanField source="published" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  );
};
