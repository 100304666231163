import React from 'react';
import { Login, useLogin } from 'react-admin';
import { Button } from '@material-ui/core';

// <LoginForm />
const LoginForm = () => {
  const login = useLogin();
  const handleLogin = () => login();
  return (
    <div style={{ textAlign: 'center', margin: '1em' }}>
      <Button variant="contained" color="primary" onClick={handleLogin}>
        Login
      </Button>
    </div>
  );
};

// <LoginPage />
const LoginPage = (props) => (
  <Login {...props}>
    <LoginForm />
  </Login>
);

export default LoginPage;
