import React from 'react';
import { Create, SimpleForm, ReferenceInput, SelectInput } from 'react-admin';

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput label="Booth" source="boothId" reference="Booth">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Activity" source="activityId" reference="Activity">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
