import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  EmailField,
  DateField,
  UrlField,
  ReferenceManyField,
  Datagrid,
  EditButton,
  ShowButton,
  DeleteButton,
  Button,
} from 'react-admin';
import { Link } from 'react-router-dom';
import LocalPlayIcon from '@material-ui/icons/LocalPlay';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
      <EmailField source="email" />
      <UrlField source="facebookLink" />
      <UrlField source="instagramLink" />
      <UrlField source="twitterLink" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceManyField
        label="Events"
        reference="Event"
        target="listEventsByOrganizer"
      >
        <Datagrid>
          <TextField source="name" />
          <EditButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      </ReferenceManyField>
      <AddNewEventButton />
    </SimpleShowLayout>
  </Show>
);

const AddNewEventButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/Event/create',
      state: {
        record: {
          organizerId: record.id,
        },
      },
    }}
    label="Add an event"
  >
    <LocalPlayIcon />
  </Button>
);
