import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { AmplifyPagination } from '../../ra-aws-amplify';
import ListActions from './ListActions';
import SyncButton from './order/SyncButton';

export default (props) => (
  <List
    {...props}
    actions={<ListActions showCreate={false} customActions={<SyncButton />} />}
    pagination={<AmplifyPagination />}
    perPage={100}
  >
    <Datagrid rowClick="show">
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="shop.name" label="Shop" />
      <TextField source="customer" />
      <TextField source="totalPrice" />
      <TextField source="paymentStatus" />
      <TextField source="fulfillment" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
