import React from 'react';
import {
  useCreateController,
  useNotify,
  useRedirect,
  Create,
  // SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
// import AWS from 'aws-sdk';
// import getKVSChannelNameFromLiveStreamId from '../../helpers/getKVSChannelNameFromLiveStreamId';
// import awsConfig from './aws-config';

// async function createSignalingChannel(config) {
//   // Create KVS client
//   const kinesisVideoClient = new AWS.KinesisVideo({
//     region: config.region,
//     accessKeyId: config.accessKeyId,
//     secretAccessKey: config.secretAccessKey,
//     sessionToken: config.sessionToken,
//     endpoint: config.endpoint,
//   });

//   // Get signaling channel ARN
//   await kinesisVideoClient
//     .createSignalingChannel({
//       ChannelName: config.channelName,
//     })
//     .promise();

//   // Get signaling channel ARN
//   const describeSignalingChannelResponse = await kinesisVideoClient
//     .describeSignalingChannel({
//       ChannelName: config.channelName,
//     })
//     .promise();
//   const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
//   console.log('[CREATE_SIGNALING_CHANNEL] Channel ARN: ', channelARN);
// }

const transform = (data) => ({
  ...data,
  id: data.boothId || data.stageId,
  stageId: data.stageId || '*',
  boothId: data.boothId || '*',
});

export default (props) => {
  const { basePath } = useCreateController(props);
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    // createSignalingChannel({
    //   ...awsConfig,
    //   channelName: getKVSChannelNameFromLiveStreamId(data.id),
    // });
    notify('ra.notification.created', 'info', { smart_count: 1 }, true);
    redirect('edit', basePath, data.id, data);
  };
  return (
    <Create {...props} transform={transform} onSuccess={onSuccess}>
      <SimpleForm
        redirect="show"
        initialValues={{
          status: 'idle',
          viewsCount: 0,
        }}
      >
        <TextInput source="stageId" disabled />
        <TextInput source="boothId" disabled />
        <TextInput source="name" />
        {/* <SelectInput
          source="status"
          choices={[
            { id: 'idle', name: 'Idle' },
            { id: 'active', name: 'Active' },
          ]}
        /> */}
      </SimpleForm>
    </Create>
  );
};
