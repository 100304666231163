import React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  BooleanField,
  ReferenceField,
  EditButton,
  ShowButton,
  DeleteButton,
} from 'react-admin';
import { S3FileField } from './aws/S3FileField';
import { S3ImageField } from './aws/S3ImageField';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <EmailField source="email" />
      <TextField source="handle" />
      <TextField source="name" />
      <TextField source="personName" />
      <TextField source="personFirstName" />
      <TextField source="personLastName" />
      <S3ImageField source="logo" />
      <S3ImageField source="storeLogo" />
      <S3ImageField source="banner" />
      <S3FileField source="bannerVideo" />
      <TextField source="shortDescription" />
      <TextField source="description" />
      <TextField source="currency" />
      <TextField source="customFields" />
      <BooleanField source="active" />
      <BooleanField source="vacation" />
      <BooleanField source="approved" />
      <BooleanField source="published" />
      <TextField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceManyField
        label="Brands"
        reference="Brand"
        target="listBrandsByShop"
      >
        <Datagrid>
          <TextField source="name" />
          <ReferenceField
            label="Event"
            source="eventId"
            reference="Event"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Products"
        reference="Product"
        target="listProductsByShop"
      >
        <Datagrid>
          <TextField source="name" />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);
