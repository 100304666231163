import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';

export default (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <NumberInput source="order" />
    </SimpleForm>
  </Edit>
);
