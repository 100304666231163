import React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  ShowButton,
  DeleteButton,
  Button,
} from 'react-admin';
import { Link } from 'react-router-dom';
import StoreIcon from '@material-ui/icons/Store';
import { S3ImageField } from './aws/S3ImageField';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="email" />
      <S3ImageField source="image" />
      <NumberField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceField
        label="Event"
        source="eventId"
        reference="Event"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Shop"
        source="shopId"
        reference="Shop"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <BoothReferenceManyField
        label="Booth"
        reference="Booth"
        target="listBoothsByBrand"
      />
      {/* <AddNewBoothButton /> */}
    </SimpleShowLayout>
  </Show>
);

const BoothReferenceManyField = (props) => (
  <ReferenceManyField {...props}>
    <SingleBoothDatagrid record={props.record} />
  </ReferenceManyField>
);

BoothReferenceManyField.defaultProps = {
  label: 'Booth',
  addLabel: true,
};

const SingleBoothDatagrid = (props) =>
  !!props.ids && !!props.ids.length ? (
    <Datagrid>
      <TextField source="name" />
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  ) : (
    <AddNewBoothButton record={props.record} />
  );

const AddNewBoothButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/Booth/create',
      state: {
        record: {
          eventId: record.eventId,
          brandId: record.id,
          name: record.name,
        },
      },
    }}
    label="Add a booth"
  >
    <StoreIcon />
  </Button>
);
