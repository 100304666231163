import React, { useState } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { AmplifyPagination } from '../../ra-aws-amplify';
import ListActions from './ListActions';
import SyncButton from './product/SyncButton';

export default (props) => {
  const [filter, setFilter] = useState(null);

  return (
    <List
      {...props}
      actions={
        <ListActions showCreate={false} customActions={<SyncButton />} />
      }
      pagination={<AmplifyPagination onNext={(filter) => setFilter(filter)} />}
      filter={filter}
      perPage={100}
    >
      <Datagrid rowClick="show">
        <TextField disabled label="Id" source="id" />
        <TextField source="handle" />
        <TextField source="name" />
        <TextField source="shop.name" label="Shop" />
      </Datagrid>
    </List>
  );
};
