import React from 'react';
import getLiveStreamUrl from '../../../helpers/getLiveStreamUrl';

const PlaybackLinkField = ({ record = {} }) => (
  <span>
    <a
      href={`${getLiveStreamUrl()}/livestreams/${record.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`${getLiveStreamUrl()}/livestreams/${record.id}`}
    </a>
  </span>
);

PlaybackLinkField.defaultProps = { label: 'Playback link', addLabel: true };

export default PlaybackLinkField;
