import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { S3ImageInput } from './aws/S3ImageInput';

const transform = (data) => {
  // Quick hack to remove nested "modulesIds"
  const { themeProperties: _themeProperties, modules: _modules, ...rest } = data;
  const themeProperties = _themeProperties.map(({ themePropertiesIds, ...rest }) => rest);
  const modules = _modules.map(({ modulesIds, ...rest }) => rest);
  return { themeProperties, modules, ...rest };
};

export default (props) => (
  <Edit {...props} transform={transform}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="slug" />
      <TextInput multiline source="details" />
      <S3ImageInput
        source="poster"
        accept="image/*"
        multiple={false}
        keyPrefix="event-poster/"
      />
      <S3ImageInput
        source="banner"
        accept="image/*"
        multiple={false}
        keyPrefix="event-banner/"
      />
      <DateTimeInput source="startingAt" />
      <DateTimeInput source="endingAt" />
      <ArrayInput source="themeProperties">
        <SimpleFormIterator disableAdd disableRemove>
          {/* <TextInput source="id" label="Id" disabled /> */}
          <TextInput source="name" label="Name" disabled />
          <ArrayInput source="themeProperties" label="Theme properties" >
            <SimpleFormIterator disableAdd disableRemove>
              {/* <TextInput source="id" label="Id" disabled /> */}
              <TextInput source="name" label="Name" disabled />
              <TextInput source="value" label="Value" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="modules">
        <SimpleFormIterator disableAdd disableRemove>
          {/* <TextInput source="id" label="Id" disabled /> */}
          <TextInput source="name" label="Name" disabled />
          <BooleanInput source="display" label="Display" />
          <NumberInput source="order" label="Order" />
          <ArrayInput source="modules" label="Modules" >
            <SimpleFormIterator disableAdd disableRemove>
              {/* <TextInput source="id" label="Id" disabled /> */}
              <TextInput source="name" label="Name" disabled />
              <BooleanInput source="display" label="Display" />
              <NumberInput source="order" label="Order" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
