/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listOrganizers = /* GraphQL */ `
  query ListOrganizers(
    $filter: ModelOrganizerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        email
        facebookLink
        instagramLink
        twitterLink
        createdAt
        updatedAt
        events {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrganizer = /* GraphQL */ `
  query GetOrganizer($id: ID!) {
    getOrganizer(id: $id) {
      id
      name
      description
      email
      facebookLink
      instagramLink
      twitterLink
      createdAt
      updatedAt
      events {
        items {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      slug
      details
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      banner {
        url
        bucket
        region
        key
        identityId
        level
      }
      startingAt
      endingAt
      themeProperties {
        id
        name
        value
        themeProperties {
          id
          name
          value
        }
      }
      modules {
        id
        name
        display
        order
        modules {
          id
          name
          display
          order
        }
      }
      organizerId
      createdAt
      updatedAt
      organizer {
        id
        name
        description
        email
        facebookLink
        instagramLink
        twitterLink
        createdAt
        updatedAt
        events {
          nextToken
        }
      }
      subscribers {
        items {
          id
          email
          metadata
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
      booths {
        items {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        nextToken
      }
      stages {
        items {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
      activities {
        items {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsors {
        items {
          id
          name
          link
          order
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listEventsByOrganizer = /* GraphQL */ `
  query ListEventsByOrganizer(
    $organizerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByOrganizer(
      organizerId: $organizerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listEventsBySlug = /* GraphQL */ `
  query ListEventsBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSubscriber = /* GraphQL */ `
  query GetSubscriber($id: ID!) {
    getSubscriber(id: $id) {
      id
      email
      metadata
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
    }
  }
`;
export const listSubscribers = /* GraphQL */ `
  query ListSubscribers(
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        metadata
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSubscribersByEvent = /* GraphQL */ `
  query ListSubscribersByEvent(
    $eventId: ID
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribersByEvent(
      eventId: $eventId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        metadata
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listBooths = /* GraphQL */ `
  query ListBooths(
    $filter: ModelBoothFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBooths(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBooth = /* GraphQL */ `
  query GetBooth($id: ID!) {
    getBooth(id: $id) {
      id
      name
      order
      eventId
      brandId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      activities {
        items {
          id
          boothId
          activityId
          createdAt
          updatedAt
        }
        nextToken
      }
      brand {
        id
        name
        email
        feeFixed
        feePercentage
        invitationMessage
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        order
        eventId
        shopId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      liveStreams {
        items {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listBoothsByEvent = /* GraphQL */ `
  query ListBoothsByEvent(
    $eventId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBoothFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoothsByEvent(
      eventId: $eventId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listBoothsByBrand = /* GraphQL */ `
  query ListBoothsByBrand(
    $brandId: ID
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBoothFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoothsByBrand(
      brandId: $brandId
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBoothActivity = /* GraphQL */ `
  query GetBoothActivity($id: ID!) {
    getBoothActivity(id: $id) {
      id
      boothId
      activityId
      createdAt
      updatedAt
      booth {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      activity {
        id
        name
        order
        summary
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        description
        startingAt
        endingAt
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        stageId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listBoothActivitys = /* GraphQL */ `
  query ListBoothActivitys(
    $filter: ModelBoothActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoothActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        boothId
        activityId
        createdAt
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        activity {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listBoothsByActivity = /* GraphQL */ `
  query ListBoothsByActivity(
    $activityId: ID
    $boothId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBoothActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoothsByActivity(
      activityId: $activityId
      boothId: $boothId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        boothId
        activityId
        createdAt
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        activity {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listActivitiesByBooth = /* GraphQL */ `
  query ListActivitiesByBooth(
    $boothId: ID
    $activityId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBoothActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitiesByBooth(
      boothId: $boothId
      activityId: $activityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        boothId
        activityId
        createdAt
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        activity {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listStages = /* GraphQL */ `
  query ListStages(
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getStage = /* GraphQL */ `
  query GetStage($id: ID!) {
    getStage(id: $id) {
      id
      name
      order
      video {
        url
        bucket
        region
        key
        identityId
        level
      }
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      activities {
        items {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      liveStreams {
        items {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listStagesByEvent = /* GraphQL */ `
  query ListStagesByEvent(
    $eventId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStagesByEvent(
      eventId: $eventId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      name
      order
      summary
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      description
      startingAt
      endingAt
      video {
        url
        bucket
        region
        key
        identityId
        level
      }
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      eventId
      stageId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      booths {
        items {
          id
          boothId
          activityId
          createdAt
          updatedAt
        }
        nextToken
      }
      stage {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order
        summary
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        description
        startingAt
        endingAt
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        stageId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listActivitiesByEvent = /* GraphQL */ `
  query ListActivitiesByEvent(
    $eventId: ID
    $startingAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitiesByEvent(
      eventId: $eventId
      startingAt: $startingAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        summary
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        description
        startingAt
        endingAt
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        stageId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listActivitiesByStage = /* GraphQL */ `
  query ListActivitiesByStage(
    $stageId: ID
    $startingAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitiesByStage(
      stageId: $stageId
      startingAt: $startingAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        order
        summary
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        description
        startingAt
        endingAt
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        stageId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      email
      feeFixed
      feePercentage
      invitationMessage
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      order
      eventId
      shopId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      booths {
        items {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        nextToken
      }
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        feeFixed
        feePercentage
        invitationMessage
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        order
        eventId
        shopId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listBrandsByEvent = /* GraphQL */ `
  query ListBrandsByEvent(
    $eventId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandsByEvent(
      eventId: $eventId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        feeFixed
        feePercentage
        invitationMessage
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        order
        eventId
        shopId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listBrandsByShop = /* GraphQL */ `
  query ListBrandsByShop(
    $shopId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandsByShop(
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        feeFixed
        feePercentage
        invitationMessage
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        order
        eventId
        shopId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSponsor = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsor(id: $id) {
      id
      name
      link
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      order
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
    }
  }
`;
export const listSponsors = /* GraphQL */ `
  query ListSponsors(
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        link
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        order
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listSponsorsByEvent = /* GraphQL */ `
  query ListSponsorsByEvent(
    $eventId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorsByEvent(
      eventId: $eventId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        link
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        order
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCustomField = /* GraphQL */ `
  query GetCustomField($id: ID!) {
    getCustomField(id: $id) {
      id
      name
      label
      type
      inputType
      displayShop
      active
      required
      order
      options {
        id
        value
      }
      wkId
      wkData {
        id
        main_id_shop
        id_custom_input_type
        custom_input_type
        display_name
        required
        input_name
        id_custom_field_page
        custom_field_page
        sequence_number
        show_on_main_shop
        active
        navision_cus_field_name
        navision_cus_field_default_val
        use_for
        show_field_on
        add_as_prod_tag
        date_add
        date_upd
        options {
          id
          dropdown_option
          date_add
          date_upd
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomFields = /* GraphQL */ `
  query ListCustomFields(
    $filter: ModelCustomFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        label
        type
        inputType
        displayShop
        active
        required
        order
        options {
          id
          value
        }
        wkId
        wkData {
          id
          main_id_shop
          id_custom_input_type
          custom_input_type
          display_name
          required
          input_name
          id_custom_field_page
          custom_field_page
          sequence_number
          show_on_main_shop
          active
          navision_cus_field_name
          navision_cus_field_default_val
          use_for
          show_field_on
          add_as_prod_tag
          date_add
          date_upd
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCustomFieldsByName = /* GraphQL */ `
  query ListCustomFieldsByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomFieldsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        label
        type
        inputType
        displayShop
        active
        required
        order
        options {
          id
          value
        }
        wkId
        wkData {
          id
          main_id_shop
          id_custom_input_type
          custom_input_type
          display_name
          required
          input_name
          id_custom_field_page
          custom_field_page
          sequence_number
          show_on_main_shop
          active
          navision_cus_field_name
          navision_cus_field_default_val
          use_for
          show_field_on
          add_as_prod_tag
          date_add
          date_upd
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCustomFieldsByType = /* GraphQL */ `
  query ListCustomFieldsByType(
    $type: String
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomFieldsByType(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        label
        type
        inputType
        displayShop
        active
        required
        order
        options {
          id
          value
        }
        wkId
        wkData {
          id
          main_id_shop
          id_custom_input_type
          custom_input_type
          display_name
          required
          input_name
          id_custom_field_page
          custom_field_page
          sequence_number
          show_on_main_shop
          active
          navision_cus_field_name
          navision_cus_field_default_val
          use_for
          show_field_on
          add_as_prod_tag
          date_add
          date_upd
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      email
      handle
      name
      personName
      personFirstName
      personLastName
      logo {
        url
        bucket
        region
        key
        identityId
        level
      }
      storeLogo {
        url
        bucket
        region
        key
        identityId
        level
      }
      banner {
        url
        bucket
        region
        key
        identityId
        level
      }
      bannerVideo {
        url
        bucket
        region
        key
        identityId
        level
      }
      shortDescription
      description
      currency
      customFields
      active
      vacation
      approved
      published
      order
      wkId
      wkData {
        id
        main_id_shop
        customer_id
        sp_shop_name
        sp_store_name
        store_name_handle
        seller_name
        last_name
        full_name
        email
        shop_logo
        store_logo
        display_customer_info
        date_add
        active
        bucket_created
        on_s3
        description
        store_address
        contact
        store_banner
        seller_banner_video
        policy
        city
        short_desc
        zipcode
        social_tabs_status
        box_folder_id
        navision_sync
        shopify_store_url
        custom_fields
        on_vacation
        is_approved
        user_type
        user_type_value
        seller_default_location_id
        sp_location_id
        is_published
        to_sell_global
        id_country
        id_state
        create_pickup_bring
        seller_currency
        seller_policy {
          id
          variable_value
          display_name
          variable_name
          input_type
          input_type_name
          description
          placeholder_value
        }
      }
      createdAt
      updatedAt
      brands {
        items {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
      products {
        items {
          id
          handle
          name
          type
          description
          price
          compareAtPrice
          salesPrice
          handlingCharges
          transactionCharges
          quantity
          active
          order
          wkId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listShopsByHandle = /* GraphQL */ `
  query ListShopsByHandle(
    $handle: String
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopsByHandle(
      handle: $handle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      handle
      name
      type
      description
      price
      compareAtPrice
      salesPrice
      handlingCharges
      transactionCharges
      quantity
      active
      order
      images {
        active
        order
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        smImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        mdImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        lgImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        alt
      }
      wkId
      wkData {
        id
        main_id_shop
        seller_id
        shopify_product_id
        product_name
        product_type
        product_description
        product_tag
        handle
        product_meta_info
        price
        compare_at_price
        sales_price
        handling_charges
        transaction_charges
        track_inventory
        quantity
        inventory_policy
        active
        has_default_shipping
        has_multiple_options
        charge_taxes
        tax_type
        hsn
        cgst
        sgst
        gst
        product_policy
        id_parent
        product_url
        ref_product_id
        expiry_date
        platform
        design_id
        date_add
        date_upd
        type {
          id
          type_name
        }
        variants {
          id
          product_id
          shopify_product_id
          shopify_variant_id
          image_id
          sku
          price
          sales_price
          handling_charges
          transaction_charges
          compare_at_price
          weight
          barcode
          track_inventory
          quantity
          requires_shipping
          date_add
          date_upd
          charge_taxes
          dimension
          inventory_policy
          id_parent
        }
        options {
          id
          name
          product_id
        }
        images {
          id
          product_id
          active
          position
          img_url
          img_sm
          img_md
          img_lg
          img_alt
          process_status
        }
        collections {
          id
          product_id
          shopify_category_id
        }
      }
      shopId
      createdAt
      updatedAt
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        handle
        name
        type
        description
        price
        compareAtPrice
        salesPrice
        handlingCharges
        transactionCharges
        quantity
        active
        order
        images {
          active
          order
          alt
        }
        wkId
        wkData {
          id
          main_id_shop
          seller_id
          shopify_product_id
          product_name
          product_type
          product_description
          product_tag
          handle
          product_meta_info
          price
          compare_at_price
          sales_price
          handling_charges
          transaction_charges
          track_inventory
          quantity
          inventory_policy
          active
          has_default_shipping
          has_multiple_options
          charge_taxes
          tax_type
          hsn
          cgst
          sgst
          gst
          product_policy
          id_parent
          product_url
          ref_product_id
          expiry_date
          platform
          design_id
          date_add
          date_upd
        }
        shopId
        createdAt
        updatedAt
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listProductsByHandle = /* GraphQL */ `
  query ListProductsByHandle(
    $handle: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByHandle(
      handle: $handle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        handle
        name
        type
        description
        price
        compareAtPrice
        salesPrice
        handlingCharges
        transactionCharges
        quantity
        active
        order
        images {
          active
          order
          alt
        }
        wkId
        wkData {
          id
          main_id_shop
          seller_id
          shopify_product_id
          product_name
          product_type
          product_description
          product_tag
          handle
          product_meta_info
          price
          compare_at_price
          sales_price
          handling_charges
          transaction_charges
          track_inventory
          quantity
          inventory_policy
          active
          has_default_shipping
          has_multiple_options
          charge_taxes
          tax_type
          hsn
          cgst
          sgst
          gst
          product_policy
          id_parent
          product_url
          ref_product_id
          expiry_date
          platform
          design_id
          date_add
          date_upd
        }
        shopId
        createdAt
        updatedAt
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listProductsByShop = /* GraphQL */ `
  query ListProductsByShop(
    $shopId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByShop(
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        handle
        name
        type
        description
        price
        compareAtPrice
        salesPrice
        handlingCharges
        transactionCharges
        quantity
        active
        order
        images {
          active
          order
          alt
        }
        wkId
        wkData {
          id
          main_id_shop
          seller_id
          shopify_product_id
          product_name
          product_type
          product_description
          product_tag
          handle
          product_meta_info
          price
          compare_at_price
          sales_price
          handling_charges
          transaction_charges
          track_inventory
          quantity
          inventory_policy
          active
          has_default_shipping
          has_multiple_options
          charge_taxes
          tax_type
          hsn
          cgst
          sgst
          gst
          product_policy
          id_parent
          product_url
          ref_product_id
          expiry_date
          platform
          design_id
          date_add
          date_upd
        }
        shopId
        createdAt
        updatedAt
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      name
      status
      statusUrl
      note
      totalPrice
      paymentStatus
      customer
      gateway
      fulfillment
      customerEmail
      address1
      address2
      company
      city
      zip
      province
      country
      phone
      billingAddress
      shippingAddress
      deliveryMethod
      transactionId
      taxShippingCommissionType
      discountCode
      discountDetails
      currency
      taxInclusive
      paid
      refunded
      gatewayProcessed
      deleted
      createdAt
      updatedAt
      wkId
      wkData {
        id
        main_id_order
        main_id_shop
        id_shop
        total_price
        payment_status
        customer
        gateway
        order_name
        fulfillment
        mp_shipping
        customer_email
        address1
        address2
        company
        city
        zip
        province
        country
        phone
        billing_address
        shipping_address
        date_add
        date_upd
        is_mail_sent
        capture_payment_mail
        order_placed_mail
        is_deleted
        order_note
        delivery_method
        is_nav_synced
        transaction_id
        order_prepare_status
        main_transaction_id
        ref_order_id
        token
        checkout_token
        is_paid
        tax_inclusive
        tax_shipping_comm_type
        is_cachable
        fulfillment_service_name
        discount_code
        discount_details
        discount_bear_by
        commission_calculated_on
        discount_difference
        order_status_url
        fulfillment_earning_cost
        is_refunded
        gateway_processed
        presentment_currency
        payment_flow
      }
      shopId
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        statusUrl
        note
        totalPrice
        paymentStatus
        customer
        gateway
        fulfillment
        customerEmail
        address1
        address2
        company
        city
        zip
        province
        country
        phone
        billingAddress
        shippingAddress
        deliveryMethod
        transactionId
        taxShippingCommissionType
        discountCode
        discountDetails
        currency
        taxInclusive
        paid
        refunded
        gatewayProcessed
        deleted
        createdAt
        updatedAt
        wkId
        wkData {
          id
          main_id_order
          main_id_shop
          id_shop
          total_price
          payment_status
          customer
          gateway
          order_name
          fulfillment
          mp_shipping
          customer_email
          address1
          address2
          company
          city
          zip
          province
          country
          phone
          billing_address
          shipping_address
          date_add
          date_upd
          is_mail_sent
          capture_payment_mail
          order_placed_mail
          is_deleted
          order_note
          delivery_method
          is_nav_synced
          transaction_id
          order_prepare_status
          main_transaction_id
          ref_order_id
          token
          checkout_token
          is_paid
          tax_inclusive
          tax_shipping_comm_type
          is_cachable
          fulfillment_service_name
          discount_code
          discount_details
          discount_bear_by
          commission_calculated_on
          discount_difference
          order_status_url
          fulfillment_earning_cost
          is_refunded
          gateway_processed
          presentment_currency
          payment_flow
        }
        shopId
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listLiveStreams = /* GraphQL */ `
  query ListLiveStreams(
    $filter: ModelLiveStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLiveStreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        thumbnail {
          url
          bucket
          region
          key
          identityId
          level
        }
        activeSpeaker
        viewsCount
        createdAt
        stageId
        boothId
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLiveStream = /* GraphQL */ `
  query GetLiveStream($id: ID!) {
    getLiveStream(id: $id) {
      id
      name
      status
      thumbnail {
        url
        bucket
        region
        key
        identityId
        level
      }
      activeSpeaker
      viewsCount
      createdAt
      stageId
      boothId
      updatedAt
      booth {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      stage {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
      messages {
        items {
          id
          text
          personId
          personName
          personCreatedAt
          createdAt
          liveStreamId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listLiveStreamsByBooth = /* GraphQL */ `
  query ListLiveStreamsByBooth(
    $boothId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLiveStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLiveStreamsByBooth(
      boothId: $boothId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        thumbnail {
          url
          bucket
          region
          key
          identityId
          level
        }
        activeSpeaker
        viewsCount
        createdAt
        stageId
        boothId
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listLiveStreamsByStage = /* GraphQL */ `
  query ListLiveStreamsByStage(
    $stageId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLiveStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLiveStreamsByStage(
      stageId: $stageId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        thumbnail {
          url
          bucket
          region
          key
          identityId
          level
        }
        activeSpeaker
        viewsCount
        createdAt
        stageId
        boothId
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      text
      personId
      personName
      personCreatedAt
      createdAt
      liveStreamId
      updatedAt
      liveStream {
        id
        name
        status
        thumbnail {
          url
          bucket
          region
          key
          identityId
          level
        }
        activeSpeaker
        viewsCount
        createdAt
        stageId
        boothId
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        personId
        personName
        personCreatedAt
        createdAt
        liveStreamId
        updatedAt
        liveStream {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listMessagesByLiveStream = /* GraphQL */ `
  query ListMessagesByLiveStream(
    $liveStreamId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesByLiveStream(
      liveStreamId: $liveStreamId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        personId
        personName
        personCreatedAt
        createdAt
        liveStreamId
        updatedAt
        liveStream {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
      }
      nextToken
    }
  }
`;
