import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
} from 'react-admin';
import { S3FileInput } from './aws/S3FileInput';
import { S3ImageInput } from './aws/S3ImageInput';

export default (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="summary" />
        <S3ImageInput
          source="image"
          accept="image/*"
          multiple={false}
          keyPrefix="activity-image/"
        />
        <TextInput source="description" />
        <DateTimeInput source="startingAt" />
        <DateTimeInput source="endingAt" />
        <S3FileInput
          source="video"
          accept="video/*"
          multiple={false}
          keyPrefix="activity-video/"
        />
        <S3ImageInput
          source="poster"
          accept="image/*"
          multiple={false}
          keyPrefix="activity-poster/"
        />
      </SimpleForm>
    </Edit>
  );
};
