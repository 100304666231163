import React from 'react';
import {
  Button,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  ShowButton,
  DeleteButton,
} from 'react-admin';
import { S3ImageField } from './aws/S3ImageField';
import { Link } from 'react-router-dom';
import ChatBubbleIcon from '@material-ui/icons/ChatBubbleRounded';
import MasterHostBroadcastLinkField from './livestream/MasterHostBroadcastLinkField';
import CoHostBroadcastLinkField from './livestream/CoHostBroadcastLinkField';
import PlaybackLinkField from './livestream/PlaybackLinkField';
import EmbedCodeField from './livestream/EmbedCodeField';

// const LinkToMessage = ({ record }) => {
//   return record ? (
//     <Button
//       color="primary"
//       component={Link}
//       to={{
//         pathname: '/Message',
//         search: stringify({
//           page: 1,
//           perPage: 25,
//           sort: 'id',
//           order: 'DESC',
//           filter: JSON.stringify({ id: record.id }),
//         }),
//       }}
//       label="View message"
//     />
//   ) : null;
// };

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="status" />
      <S3ImageField source="thumbnail" />
      <TextField source="activeSpeaker" />
      <NumberField source="viewsCount" />
      <MasterHostBroadcastLinkField />
      <CoHostBroadcastLinkField />
      <PlaybackLinkField />
      <EmbedCodeField />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceManyField
        label="Messages"
        reference="Message"
        target="listMessagesByLiveStream"
      >
        <Datagrid>
          <TextField source="text" />
          <DateField source="createdAt" />
          <EditButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      </ReferenceManyField>
      <AddNewMessageButton />
    </SimpleShowLayout>
  </Show>
);

const AddNewMessageButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/Message/create',
      search: '?liveStreamId=' + record.id,
    }}
    label="Add a message"
  >
    <ChatBubbleIcon />
  </Button>
);
