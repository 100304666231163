/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessageForLiveStream = /* GraphQL */ `
  mutation CreateMessageForLiveStream(
    $liveStreamId: ID!
    $input: CreateMessageForLiveStreamInput
  ) {
    createMessageForLiveStream(liveStreamId: $liveStreamId, input: $input) {
      id
      text
      personId
      personName
      personCreatedAt
      createdAt
      liveStreamId
      updatedAt
      liveStream {
        id
        name
        status
        thumbnail {
          url
          bucket
          region
          key
          identityId
          level
        }
        activeSpeaker
        viewsCount
        createdAt
        stageId
        boothId
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const updateLiveStreamIncrementViewsCount = /* GraphQL */ `
  mutation UpdateLiveStreamIncrementViewsCount(
    $input: UpdateLiveStreamViewsCountInput
  ) {
    updateLiveStreamIncrementViewsCount(input: $input) {
      id
      name
      status
      thumbnail {
        url
        bucket
        region
        key
        identityId
        level
      }
      activeSpeaker
      viewsCount
      createdAt
      stageId
      boothId
      updatedAt
      booth {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      stage {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
      messages {
        items {
          id
          text
          personId
          personName
          personCreatedAt
          createdAt
          liveStreamId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createOrganizer = /* GraphQL */ `
  mutation CreateOrganizer(
    $input: CreateOrganizerInput!
    $condition: ModelOrganizerConditionInput
  ) {
    createOrganizer(input: $input, condition: $condition) {
      id
      name
      description
      email
      facebookLink
      instagramLink
      twitterLink
      createdAt
      updatedAt
      events {
        items {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOrganizer = /* GraphQL */ `
  mutation UpdateOrganizer(
    $input: UpdateOrganizerInput!
    $condition: ModelOrganizerConditionInput
  ) {
    updateOrganizer(input: $input, condition: $condition) {
      id
      name
      description
      email
      facebookLink
      instagramLink
      twitterLink
      createdAt
      updatedAt
      events {
        items {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOrganizer = /* GraphQL */ `
  mutation DeleteOrganizer(
    $input: DeleteOrganizerInput!
    $condition: ModelOrganizerConditionInput
  ) {
    deleteOrganizer(input: $input, condition: $condition) {
      id
      name
      description
      email
      facebookLink
      instagramLink
      twitterLink
      createdAt
      updatedAt
      events {
        items {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      slug
      details
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      banner {
        url
        bucket
        region
        key
        identityId
        level
      }
      startingAt
      endingAt
      themeProperties {
        id
        name
        value
        themeProperties {
          id
          name
          value
        }
      }
      modules {
        id
        name
        display
        order
        modules {
          id
          name
          display
          order
        }
      }
      organizerId
      createdAt
      updatedAt
      organizer {
        id
        name
        description
        email
        facebookLink
        instagramLink
        twitterLink
        createdAt
        updatedAt
        events {
          nextToken
        }
      }
      subscribers {
        items {
          id
          email
          metadata
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
      booths {
        items {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        nextToken
      }
      stages {
        items {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
      activities {
        items {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsors {
        items {
          id
          name
          link
          order
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      slug
      details
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      banner {
        url
        bucket
        region
        key
        identityId
        level
      }
      startingAt
      endingAt
      themeProperties {
        id
        name
        value
        themeProperties {
          id
          name
          value
        }
      }
      modules {
        id
        name
        display
        order
        modules {
          id
          name
          display
          order
        }
      }
      organizerId
      createdAt
      updatedAt
      organizer {
        id
        name
        description
        email
        facebookLink
        instagramLink
        twitterLink
        createdAt
        updatedAt
        events {
          nextToken
        }
      }
      subscribers {
        items {
          id
          email
          metadata
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
      booths {
        items {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        nextToken
      }
      stages {
        items {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
      activities {
        items {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsors {
        items {
          id
          name
          link
          order
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      slug
      details
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      banner {
        url
        bucket
        region
        key
        identityId
        level
      }
      startingAt
      endingAt
      themeProperties {
        id
        name
        value
        themeProperties {
          id
          name
          value
        }
      }
      modules {
        id
        name
        display
        order
        modules {
          id
          name
          display
          order
        }
      }
      organizerId
      createdAt
      updatedAt
      organizer {
        id
        name
        description
        email
        facebookLink
        instagramLink
        twitterLink
        createdAt
        updatedAt
        events {
          nextToken
        }
      }
      subscribers {
        items {
          id
          email
          metadata
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
      booths {
        items {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        nextToken
      }
      stages {
        items {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
      activities {
        items {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      brands {
        items {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
      sponsors {
        items {
          id
          name
          link
          order
          eventId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSubscriber = /* GraphQL */ `
  mutation CreateSubscriber(
    $input: CreateSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    createSubscriber(input: $input, condition: $condition) {
      id
      email
      metadata
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
    }
  }
`;
export const updateSubscriber = /* GraphQL */ `
  mutation UpdateSubscriber(
    $input: UpdateSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    updateSubscriber(input: $input, condition: $condition) {
      id
      email
      metadata
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
    }
  }
`;
export const deleteSubscriber = /* GraphQL */ `
  mutation DeleteSubscriber(
    $input: DeleteSubscriberInput!
    $condition: ModelSubscriberConditionInput
  ) {
    deleteSubscriber(input: $input, condition: $condition) {
      id
      email
      metadata
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
    }
  }
`;
export const createBooth = /* GraphQL */ `
  mutation CreateBooth(
    $input: CreateBoothInput!
    $condition: ModelBoothConditionInput
  ) {
    createBooth(input: $input, condition: $condition) {
      id
      name
      order
      eventId
      brandId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      activities {
        items {
          id
          boothId
          activityId
          createdAt
          updatedAt
        }
        nextToken
      }
      brand {
        id
        name
        email
        feeFixed
        feePercentage
        invitationMessage
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        order
        eventId
        shopId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      liveStreams {
        items {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBooth = /* GraphQL */ `
  mutation UpdateBooth(
    $input: UpdateBoothInput!
    $condition: ModelBoothConditionInput
  ) {
    updateBooth(input: $input, condition: $condition) {
      id
      name
      order
      eventId
      brandId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      activities {
        items {
          id
          boothId
          activityId
          createdAt
          updatedAt
        }
        nextToken
      }
      brand {
        id
        name
        email
        feeFixed
        feePercentage
        invitationMessage
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        order
        eventId
        shopId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      liveStreams {
        items {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBooth = /* GraphQL */ `
  mutation DeleteBooth(
    $input: DeleteBoothInput!
    $condition: ModelBoothConditionInput
  ) {
    deleteBooth(input: $input, condition: $condition) {
      id
      name
      order
      eventId
      brandId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      activities {
        items {
          id
          boothId
          activityId
          createdAt
          updatedAt
        }
        nextToken
      }
      brand {
        id
        name
        email
        feeFixed
        feePercentage
        invitationMessage
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        order
        eventId
        shopId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        shop {
          id
          email
          handle
          name
          personName
          personFirstName
          personLastName
          shortDescription
          description
          currency
          customFields
          active
          vacation
          approved
          published
          order
          wkId
          createdAt
          updatedAt
        }
      }
      liveStreams {
        items {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBoothActivity = /* GraphQL */ `
  mutation CreateBoothActivity(
    $input: CreateBoothActivityInput!
    $condition: ModelBoothActivityConditionInput
  ) {
    createBoothActivity(input: $input, condition: $condition) {
      id
      boothId
      activityId
      createdAt
      updatedAt
      booth {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      activity {
        id
        name
        order
        summary
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        description
        startingAt
        endingAt
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        stageId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateBoothActivity = /* GraphQL */ `
  mutation UpdateBoothActivity(
    $input: UpdateBoothActivityInput!
    $condition: ModelBoothActivityConditionInput
  ) {
    updateBoothActivity(input: $input, condition: $condition) {
      id
      boothId
      activityId
      createdAt
      updatedAt
      booth {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      activity {
        id
        name
        order
        summary
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        description
        startingAt
        endingAt
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        stageId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteBoothActivity = /* GraphQL */ `
  mutation DeleteBoothActivity(
    $input: DeleteBoothActivityInput!
    $condition: ModelBoothActivityConditionInput
  ) {
    deleteBoothActivity(input: $input, condition: $condition) {
      id
      boothId
      activityId
      createdAt
      updatedAt
      booth {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      activity {
        id
        name
        order
        summary
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        description
        startingAt
        endingAt
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        stageId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        booths {
          nextToken
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createStage = /* GraphQL */ `
  mutation CreateStage(
    $input: CreateStageInput!
    $condition: ModelStageConditionInput
  ) {
    createStage(input: $input, condition: $condition) {
      id
      name
      order
      video {
        url
        bucket
        region
        key
        identityId
        level
      }
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      activities {
        items {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      liveStreams {
        items {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateStage = /* GraphQL */ `
  mutation UpdateStage(
    $input: UpdateStageInput!
    $condition: ModelStageConditionInput
  ) {
    updateStage(input: $input, condition: $condition) {
      id
      name
      order
      video {
        url
        bucket
        region
        key
        identityId
        level
      }
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      activities {
        items {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      liveStreams {
        items {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteStage = /* GraphQL */ `
  mutation DeleteStage(
    $input: DeleteStageInput!
    $condition: ModelStageConditionInput
  ) {
    deleteStage(input: $input, condition: $condition) {
      id
      name
      order
      video {
        url
        bucket
        region
        key
        identityId
        level
      }
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      activities {
        items {
          id
          name
          order
          summary
          description
          startingAt
          endingAt
          eventId
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      liveStreams {
        items {
          id
          name
          status
          activeSpeaker
          viewsCount
          createdAt
          stageId
          boothId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      name
      order
      summary
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      description
      startingAt
      endingAt
      video {
        url
        bucket
        region
        key
        identityId
        level
      }
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      eventId
      stageId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      booths {
        items {
          id
          boothId
          activityId
          createdAt
          updatedAt
        }
        nextToken
      }
      stage {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      name
      order
      summary
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      description
      startingAt
      endingAt
      video {
        url
        bucket
        region
        key
        identityId
        level
      }
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      eventId
      stageId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      booths {
        items {
          id
          boothId
          activityId
          createdAt
          updatedAt
        }
        nextToken
      }
      stage {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      name
      order
      summary
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      description
      startingAt
      endingAt
      video {
        url
        bucket
        region
        key
        identityId
        level
      }
      poster {
        url
        bucket
        region
        key
        identityId
        level
      }
      eventId
      stageId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      booths {
        items {
          id
          boothId
          activityId
          createdAt
          updatedAt
        }
        nextToken
      }
      stage {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      name
      email
      feeFixed
      feePercentage
      invitationMessage
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      order
      eventId
      shopId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      booths {
        items {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        nextToken
      }
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      name
      email
      feeFixed
      feePercentage
      invitationMessage
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      order
      eventId
      shopId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      booths {
        items {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        nextToken
      }
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      name
      email
      feeFixed
      feePercentage
      invitationMessage
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      order
      eventId
      shopId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
      booths {
        items {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        nextToken
      }
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const createSponsor = /* GraphQL */ `
  mutation CreateSponsor(
    $input: CreateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    createSponsor(input: $input, condition: $condition) {
      id
      name
      link
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      order
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
    }
  }
`;
export const updateSponsor = /* GraphQL */ `
  mutation UpdateSponsor(
    $input: UpdateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    updateSponsor(input: $input, condition: $condition) {
      id
      name
      link
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      order
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
    }
  }
`;
export const deleteSponsor = /* GraphQL */ `
  mutation DeleteSponsor(
    $input: DeleteSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    deleteSponsor(input: $input, condition: $condition) {
      id
      name
      link
      image {
        url
        bucket
        region
        key
        identityId
        level
      }
      order
      eventId
      createdAt
      updatedAt
      event {
        id
        name
        slug
        details
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        startingAt
        endingAt
        themeProperties {
          id
          name
          value
        }
        modules {
          id
          name
          display
          order
        }
        organizerId
        createdAt
        updatedAt
        organizer {
          id
          name
          description
          email
          facebookLink
          instagramLink
          twitterLink
          createdAt
          updatedAt
        }
        subscribers {
          nextToken
        }
        booths {
          nextToken
        }
        stages {
          nextToken
        }
        activities {
          nextToken
        }
        brands {
          nextToken
        }
        sponsors {
          nextToken
        }
      }
    }
  }
`;
export const createCustomField = /* GraphQL */ `
  mutation CreateCustomField(
    $input: CreateCustomFieldInput!
    $condition: ModelCustomFieldConditionInput
  ) {
    createCustomField(input: $input, condition: $condition) {
      id
      name
      label
      type
      inputType
      displayShop
      active
      required
      order
      options {
        id
        value
      }
      wkId
      wkData {
        id
        main_id_shop
        id_custom_input_type
        custom_input_type
        display_name
        required
        input_name
        id_custom_field_page
        custom_field_page
        sequence_number
        show_on_main_shop
        active
        navision_cus_field_name
        navision_cus_field_default_val
        use_for
        show_field_on
        add_as_prod_tag
        date_add
        date_upd
        options {
          id
          dropdown_option
          date_add
          date_upd
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomField = /* GraphQL */ `
  mutation UpdateCustomField(
    $input: UpdateCustomFieldInput!
    $condition: ModelCustomFieldConditionInput
  ) {
    updateCustomField(input: $input, condition: $condition) {
      id
      name
      label
      type
      inputType
      displayShop
      active
      required
      order
      options {
        id
        value
      }
      wkId
      wkData {
        id
        main_id_shop
        id_custom_input_type
        custom_input_type
        display_name
        required
        input_name
        id_custom_field_page
        custom_field_page
        sequence_number
        show_on_main_shop
        active
        navision_cus_field_name
        navision_cus_field_default_val
        use_for
        show_field_on
        add_as_prod_tag
        date_add
        date_upd
        options {
          id
          dropdown_option
          date_add
          date_upd
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomField = /* GraphQL */ `
  mutation DeleteCustomField(
    $input: DeleteCustomFieldInput!
    $condition: ModelCustomFieldConditionInput
  ) {
    deleteCustomField(input: $input, condition: $condition) {
      id
      name
      label
      type
      inputType
      displayShop
      active
      required
      order
      options {
        id
        value
      }
      wkId
      wkData {
        id
        main_id_shop
        id_custom_input_type
        custom_input_type
        display_name
        required
        input_name
        id_custom_field_page
        custom_field_page
        sequence_number
        show_on_main_shop
        active
        navision_cus_field_name
        navision_cus_field_default_val
        use_for
        show_field_on
        add_as_prod_tag
        date_add
        date_upd
        options {
          id
          dropdown_option
          date_add
          date_upd
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      email
      handle
      name
      personName
      personFirstName
      personLastName
      logo {
        url
        bucket
        region
        key
        identityId
        level
      }
      storeLogo {
        url
        bucket
        region
        key
        identityId
        level
      }
      banner {
        url
        bucket
        region
        key
        identityId
        level
      }
      bannerVideo {
        url
        bucket
        region
        key
        identityId
        level
      }
      shortDescription
      description
      currency
      customFields
      active
      vacation
      approved
      published
      order
      wkId
      wkData {
        id
        main_id_shop
        customer_id
        sp_shop_name
        sp_store_name
        store_name_handle
        seller_name
        last_name
        full_name
        email
        shop_logo
        store_logo
        display_customer_info
        date_add
        active
        bucket_created
        on_s3
        description
        store_address
        contact
        store_banner
        seller_banner_video
        policy
        city
        short_desc
        zipcode
        social_tabs_status
        box_folder_id
        navision_sync
        shopify_store_url
        custom_fields
        on_vacation
        is_approved
        user_type
        user_type_value
        seller_default_location_id
        sp_location_id
        is_published
        to_sell_global
        id_country
        id_state
        create_pickup_bring
        seller_currency
        seller_policy {
          id
          variable_value
          display_name
          variable_name
          input_type
          input_type_name
          description
          placeholder_value
        }
      }
      createdAt
      updatedAt
      brands {
        items {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
      products {
        items {
          id
          handle
          name
          type
          description
          price
          compareAtPrice
          salesPrice
          handlingCharges
          transactionCharges
          quantity
          active
          order
          wkId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      email
      handle
      name
      personName
      personFirstName
      personLastName
      logo {
        url
        bucket
        region
        key
        identityId
        level
      }
      storeLogo {
        url
        bucket
        region
        key
        identityId
        level
      }
      banner {
        url
        bucket
        region
        key
        identityId
        level
      }
      bannerVideo {
        url
        bucket
        region
        key
        identityId
        level
      }
      shortDescription
      description
      currency
      customFields
      active
      vacation
      approved
      published
      order
      wkId
      wkData {
        id
        main_id_shop
        customer_id
        sp_shop_name
        sp_store_name
        store_name_handle
        seller_name
        last_name
        full_name
        email
        shop_logo
        store_logo
        display_customer_info
        date_add
        active
        bucket_created
        on_s3
        description
        store_address
        contact
        store_banner
        seller_banner_video
        policy
        city
        short_desc
        zipcode
        social_tabs_status
        box_folder_id
        navision_sync
        shopify_store_url
        custom_fields
        on_vacation
        is_approved
        user_type
        user_type_value
        seller_default_location_id
        sp_location_id
        is_published
        to_sell_global
        id_country
        id_state
        create_pickup_bring
        seller_currency
        seller_policy {
          id
          variable_value
          display_name
          variable_name
          input_type
          input_type_name
          description
          placeholder_value
        }
      }
      createdAt
      updatedAt
      brands {
        items {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
      products {
        items {
          id
          handle
          name
          type
          description
          price
          compareAtPrice
          salesPrice
          handlingCharges
          transactionCharges
          quantity
          active
          order
          wkId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      email
      handle
      name
      personName
      personFirstName
      personLastName
      logo {
        url
        bucket
        region
        key
        identityId
        level
      }
      storeLogo {
        url
        bucket
        region
        key
        identityId
        level
      }
      banner {
        url
        bucket
        region
        key
        identityId
        level
      }
      bannerVideo {
        url
        bucket
        region
        key
        identityId
        level
      }
      shortDescription
      description
      currency
      customFields
      active
      vacation
      approved
      published
      order
      wkId
      wkData {
        id
        main_id_shop
        customer_id
        sp_shop_name
        sp_store_name
        store_name_handle
        seller_name
        last_name
        full_name
        email
        shop_logo
        store_logo
        display_customer_info
        date_add
        active
        bucket_created
        on_s3
        description
        store_address
        contact
        store_banner
        seller_banner_video
        policy
        city
        short_desc
        zipcode
        social_tabs_status
        box_folder_id
        navision_sync
        shopify_store_url
        custom_fields
        on_vacation
        is_approved
        user_type
        user_type_value
        seller_default_location_id
        sp_location_id
        is_published
        to_sell_global
        id_country
        id_state
        create_pickup_bring
        seller_currency
        seller_policy {
          id
          variable_value
          display_name
          variable_name
          input_type
          input_type_name
          description
          placeholder_value
        }
      }
      createdAt
      updatedAt
      brands {
        items {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
      products {
        items {
          id
          handle
          name
          type
          description
          price
          compareAtPrice
          salesPrice
          handlingCharges
          transactionCharges
          quantity
          active
          order
          wkId
          shopId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      handle
      name
      type
      description
      price
      compareAtPrice
      salesPrice
      handlingCharges
      transactionCharges
      quantity
      active
      order
      images {
        active
        order
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        smImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        mdImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        lgImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        alt
      }
      wkId
      wkData {
        id
        main_id_shop
        seller_id
        shopify_product_id
        product_name
        product_type
        product_description
        product_tag
        handle
        product_meta_info
        price
        compare_at_price
        sales_price
        handling_charges
        transaction_charges
        track_inventory
        quantity
        inventory_policy
        active
        has_default_shipping
        has_multiple_options
        charge_taxes
        tax_type
        hsn
        cgst
        sgst
        gst
        product_policy
        id_parent
        product_url
        ref_product_id
        expiry_date
        platform
        design_id
        date_add
        date_upd
        type {
          id
          type_name
        }
        variants {
          id
          product_id
          shopify_product_id
          shopify_variant_id
          image_id
          sku
          price
          sales_price
          handling_charges
          transaction_charges
          compare_at_price
          weight
          barcode
          track_inventory
          quantity
          requires_shipping
          date_add
          date_upd
          charge_taxes
          dimension
          inventory_policy
          id_parent
        }
        options {
          id
          name
          product_id
        }
        images {
          id
          product_id
          active
          position
          img_url
          img_sm
          img_md
          img_lg
          img_alt
          process_status
        }
        collections {
          id
          product_id
          shopify_category_id
        }
      }
      shopId
      createdAt
      updatedAt
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      handle
      name
      type
      description
      price
      compareAtPrice
      salesPrice
      handlingCharges
      transactionCharges
      quantity
      active
      order
      images {
        active
        order
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        smImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        mdImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        lgImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        alt
      }
      wkId
      wkData {
        id
        main_id_shop
        seller_id
        shopify_product_id
        product_name
        product_type
        product_description
        product_tag
        handle
        product_meta_info
        price
        compare_at_price
        sales_price
        handling_charges
        transaction_charges
        track_inventory
        quantity
        inventory_policy
        active
        has_default_shipping
        has_multiple_options
        charge_taxes
        tax_type
        hsn
        cgst
        sgst
        gst
        product_policy
        id_parent
        product_url
        ref_product_id
        expiry_date
        platform
        design_id
        date_add
        date_upd
        type {
          id
          type_name
        }
        variants {
          id
          product_id
          shopify_product_id
          shopify_variant_id
          image_id
          sku
          price
          sales_price
          handling_charges
          transaction_charges
          compare_at_price
          weight
          barcode
          track_inventory
          quantity
          requires_shipping
          date_add
          date_upd
          charge_taxes
          dimension
          inventory_policy
          id_parent
        }
        options {
          id
          name
          product_id
        }
        images {
          id
          product_id
          active
          position
          img_url
          img_sm
          img_md
          img_lg
          img_alt
          process_status
        }
        collections {
          id
          product_id
          shopify_category_id
        }
      }
      shopId
      createdAt
      updatedAt
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      handle
      name
      type
      description
      price
      compareAtPrice
      salesPrice
      handlingCharges
      transactionCharges
      quantity
      active
      order
      images {
        active
        order
        image {
          url
          bucket
          region
          key
          identityId
          level
        }
        smImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        mdImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        lgImage {
          url
          bucket
          region
          key
          identityId
          level
        }
        alt
      }
      wkId
      wkData {
        id
        main_id_shop
        seller_id
        shopify_product_id
        product_name
        product_type
        product_description
        product_tag
        handle
        product_meta_info
        price
        compare_at_price
        sales_price
        handling_charges
        transaction_charges
        track_inventory
        quantity
        inventory_policy
        active
        has_default_shipping
        has_multiple_options
        charge_taxes
        tax_type
        hsn
        cgst
        sgst
        gst
        product_policy
        id_parent
        product_url
        ref_product_id
        expiry_date
        platform
        design_id
        date_add
        date_upd
        type {
          id
          type_name
        }
        variants {
          id
          product_id
          shopify_product_id
          shopify_variant_id
          image_id
          sku
          price
          sales_price
          handling_charges
          transaction_charges
          compare_at_price
          weight
          barcode
          track_inventory
          quantity
          requires_shipping
          date_add
          date_upd
          charge_taxes
          dimension
          inventory_policy
          id_parent
        }
        options {
          id
          name
          product_id
        }
        images {
          id
          product_id
          active
          position
          img_url
          img_sm
          img_md
          img_lg
          img_alt
          process_status
        }
        collections {
          id
          product_id
          shopify_category_id
        }
      }
      shopId
      createdAt
      updatedAt
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      name
      status
      statusUrl
      note
      totalPrice
      paymentStatus
      customer
      gateway
      fulfillment
      customerEmail
      address1
      address2
      company
      city
      zip
      province
      country
      phone
      billingAddress
      shippingAddress
      deliveryMethod
      transactionId
      taxShippingCommissionType
      discountCode
      discountDetails
      currency
      taxInclusive
      paid
      refunded
      gatewayProcessed
      deleted
      createdAt
      updatedAt
      wkId
      wkData {
        id
        main_id_order
        main_id_shop
        id_shop
        total_price
        payment_status
        customer
        gateway
        order_name
        fulfillment
        mp_shipping
        customer_email
        address1
        address2
        company
        city
        zip
        province
        country
        phone
        billing_address
        shipping_address
        date_add
        date_upd
        is_mail_sent
        capture_payment_mail
        order_placed_mail
        is_deleted
        order_note
        delivery_method
        is_nav_synced
        transaction_id
        order_prepare_status
        main_transaction_id
        ref_order_id
        token
        checkout_token
        is_paid
        tax_inclusive
        tax_shipping_comm_type
        is_cachable
        fulfillment_service_name
        discount_code
        discount_details
        discount_bear_by
        commission_calculated_on
        discount_difference
        order_status_url
        fulfillment_earning_cost
        is_refunded
        gateway_processed
        presentment_currency
        payment_flow
      }
      shopId
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      name
      status
      statusUrl
      note
      totalPrice
      paymentStatus
      customer
      gateway
      fulfillment
      customerEmail
      address1
      address2
      company
      city
      zip
      province
      country
      phone
      billingAddress
      shippingAddress
      deliveryMethod
      transactionId
      taxShippingCommissionType
      discountCode
      discountDetails
      currency
      taxInclusive
      paid
      refunded
      gatewayProcessed
      deleted
      createdAt
      updatedAt
      wkId
      wkData {
        id
        main_id_order
        main_id_shop
        id_shop
        total_price
        payment_status
        customer
        gateway
        order_name
        fulfillment
        mp_shipping
        customer_email
        address1
        address2
        company
        city
        zip
        province
        country
        phone
        billing_address
        shipping_address
        date_add
        date_upd
        is_mail_sent
        capture_payment_mail
        order_placed_mail
        is_deleted
        order_note
        delivery_method
        is_nav_synced
        transaction_id
        order_prepare_status
        main_transaction_id
        ref_order_id
        token
        checkout_token
        is_paid
        tax_inclusive
        tax_shipping_comm_type
        is_cachable
        fulfillment_service_name
        discount_code
        discount_details
        discount_bear_by
        commission_calculated_on
        discount_difference
        order_status_url
        fulfillment_earning_cost
        is_refunded
        gateway_processed
        presentment_currency
        payment_flow
      }
      shopId
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      name
      status
      statusUrl
      note
      totalPrice
      paymentStatus
      customer
      gateway
      fulfillment
      customerEmail
      address1
      address2
      company
      city
      zip
      province
      country
      phone
      billingAddress
      shippingAddress
      deliveryMethod
      transactionId
      taxShippingCommissionType
      discountCode
      discountDetails
      currency
      taxInclusive
      paid
      refunded
      gatewayProcessed
      deleted
      createdAt
      updatedAt
      wkId
      wkData {
        id
        main_id_order
        main_id_shop
        id_shop
        total_price
        payment_status
        customer
        gateway
        order_name
        fulfillment
        mp_shipping
        customer_email
        address1
        address2
        company
        city
        zip
        province
        country
        phone
        billing_address
        shipping_address
        date_add
        date_upd
        is_mail_sent
        capture_payment_mail
        order_placed_mail
        is_deleted
        order_note
        delivery_method
        is_nav_synced
        transaction_id
        order_prepare_status
        main_transaction_id
        ref_order_id
        token
        checkout_token
        is_paid
        tax_inclusive
        tax_shipping_comm_type
        is_cachable
        fulfillment_service_name
        discount_code
        discount_details
        discount_bear_by
        commission_calculated_on
        discount_difference
        order_status_url
        fulfillment_earning_cost
        is_refunded
        gateway_processed
        presentment_currency
        payment_flow
      }
      shopId
      shop {
        id
        email
        handle
        name
        personName
        personFirstName
        personLastName
        logo {
          url
          bucket
          region
          key
          identityId
          level
        }
        storeLogo {
          url
          bucket
          region
          key
          identityId
          level
        }
        banner {
          url
          bucket
          region
          key
          identityId
          level
        }
        bannerVideo {
          url
          bucket
          region
          key
          identityId
          level
        }
        shortDescription
        description
        currency
        customFields
        active
        vacation
        approved
        published
        order
        wkId
        wkData {
          id
          main_id_shop
          customer_id
          sp_shop_name
          sp_store_name
          store_name_handle
          seller_name
          last_name
          full_name
          email
          shop_logo
          store_logo
          display_customer_info
          date_add
          active
          bucket_created
          on_s3
          description
          store_address
          contact
          store_banner
          seller_banner_video
          policy
          city
          short_desc
          zipcode
          social_tabs_status
          box_folder_id
          navision_sync
          shopify_store_url
          custom_fields
          on_vacation
          is_approved
          user_type
          user_type_value
          seller_default_location_id
          sp_location_id
          is_published
          to_sell_global
          id_country
          id_state
          create_pickup_bring
          seller_currency
        }
        createdAt
        updatedAt
        brands {
          nextToken
        }
        products {
          nextToken
        }
      }
    }
  }
`;
export const createLiveStream = /* GraphQL */ `
  mutation CreateLiveStream(
    $input: CreateLiveStreamInput!
    $condition: ModelLiveStreamConditionInput
  ) {
    createLiveStream(input: $input, condition: $condition) {
      id
      name
      status
      thumbnail {
        url
        bucket
        region
        key
        identityId
        level
      }
      activeSpeaker
      viewsCount
      createdAt
      stageId
      boothId
      updatedAt
      booth {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      stage {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
      messages {
        items {
          id
          text
          personId
          personName
          personCreatedAt
          createdAt
          liveStreamId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateLiveStream = /* GraphQL */ `
  mutation UpdateLiveStream(
    $input: UpdateLiveStreamInput!
    $condition: ModelLiveStreamConditionInput
  ) {
    updateLiveStream(input: $input, condition: $condition) {
      id
      name
      status
      thumbnail {
        url
        bucket
        region
        key
        identityId
        level
      }
      activeSpeaker
      viewsCount
      createdAt
      stageId
      boothId
      updatedAt
      booth {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      stage {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
      messages {
        items {
          id
          text
          personId
          personName
          personCreatedAt
          createdAt
          liveStreamId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteLiveStream = /* GraphQL */ `
  mutation DeleteLiveStream(
    $input: DeleteLiveStreamInput!
    $condition: ModelLiveStreamConditionInput
  ) {
    deleteLiveStream(input: $input, condition: $condition) {
      id
      name
      status
      thumbnail {
        url
        bucket
        region
        key
        identityId
        level
      }
      activeSpeaker
      viewsCount
      createdAt
      stageId
      boothId
      updatedAt
      booth {
        id
        name
        order
        eventId
        brandId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        brand {
          id
          name
          email
          feeFixed
          feePercentage
          invitationMessage
          order
          eventId
          shopId
          createdAt
          updatedAt
        }
        liveStreams {
          nextToken
        }
      }
      stage {
        id
        name
        order
        video {
          url
          bucket
          region
          key
          identityId
          level
        }
        poster {
          url
          bucket
          region
          key
          identityId
          level
        }
        eventId
        createdAt
        updatedAt
        event {
          id
          name
          slug
          details
          startingAt
          endingAt
          organizerId
          createdAt
          updatedAt
        }
        activities {
          nextToken
        }
        liveStreams {
          nextToken
        }
      }
      messages {
        items {
          id
          text
          personId
          personName
          personCreatedAt
          createdAt
          liveStreamId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      text
      personId
      personName
      personCreatedAt
      createdAt
      liveStreamId
      updatedAt
      liveStream {
        id
        name
        status
        thumbnail {
          url
          bucket
          region
          key
          identityId
          level
        }
        activeSpeaker
        viewsCount
        createdAt
        stageId
        boothId
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      text
      personId
      personName
      personCreatedAt
      createdAt
      liveStreamId
      updatedAt
      liveStream {
        id
        name
        status
        thumbnail {
          url
          bucket
          region
          key
          identityId
          level
        }
        activeSpeaker
        viewsCount
        createdAt
        stageId
        boothId
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      text
      personId
      personName
      personCreatedAt
      createdAt
      liveStreamId
      updatedAt
      liveStream {
        id
        name
        status
        thumbnail {
          url
          bucket
          region
          key
          identityId
          level
        }
        activeSpeaker
        viewsCount
        createdAt
        stageId
        boothId
        updatedAt
        booth {
          id
          name
          order
          eventId
          brandId
          createdAt
          updatedAt
        }
        stage {
          id
          name
          order
          eventId
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
      }
    }
  }
`;
