import React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  TextField,
  NumberField,
  DateField,
} from 'react-admin';
import { S3ImageField } from './aws/S3ImageField';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="link" />
      <S3ImageField source="image" />
      <NumberField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceField
        label="Event"
        source="eventId"
        reference="Event"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
