import React from 'react';
import { useInput, useNotify, usePermissions } from 'react-admin';
import Storage from '@aws-amplify/storage';
import { v4 as uuid } from 'uuid';

import config from '../../../aws-exports';

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

export const S3Input = ({
  source,
  dropzoneOptions = {},
  level = 'public',
  children,
  ...props
}) => {
  // we use permissions to grab the identityId
  const { permissions } = usePermissions();
  const { input } = useInput({ source });
  const notify = useNotify();

  /**
   * Handle the react-dropzone onDrop
   * @param {File[]} files files dropped onto the upload area
   */
  const onDrop = async (files) => {
    try {
      const results = await Promise.all(
        files.map((file) => {
          const nameParts = file.name.split('.');
          return Storage.put(
            `${props.keyPrefix}${uuid()}.${nameParts[1]}`,
            file
          ).then((result) => {
            let value = {
              bucket,
              region,
              key: result.key,
              type: file.type,
              level: null,
              identityId: null,
            };
            if (level === 'protected' || level === 'private') {
              value.identityId = permissions.claims.identityId;
              value.level = level;
            }
            return value;
          });
        })
      );

      if (props.multiple) {
        input.onChange(results);
      } else {
        input.onChange(results[0]);
      }
    } catch (error) {
      console.log(error);
      input.onChange(undefined);
      notify('There was an error uploading your files.');
    }
  };

  const childProps = {
    source,
    options: { onDrop, ...dropzoneOptions },
    ...props,
  };

  // @ts-ignore
  return React.cloneElement(children, childProps);
};
