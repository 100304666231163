import React from 'react';
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';
import { AmplifyPagination } from '../../ra-aws-amplify';
import ListActions from './ListActions';
import ReconnectButton from './brand/ReconnectButton';

export default (props) => (
  <List
    {...props}
    actions={
      <ListActions showCreate={false} customActions={<ReconnectButton />} />
    }
    pagination={<AmplifyPagination />}
    perPage={100}
  >
    <Datagrid rowClick="show">
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="shop.name" label="Shop" />
      <BooleanField source="shop.active" label="Shop.Active" />
      <BooleanField source="shop.vacation" label="Shop.Vacation" />
      <BooleanField source="shop.approved" label="Shop.Approved" />
      <BooleanField source="shop.published" label="Shop.Published" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
