import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';
import { S3ImageInput } from './aws/S3ImageInput';

export default (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <TextInput source="email" disabled />
      <S3ImageInput
        source="image"
        accept="image/*"
        multiple={false}
        keyPrefix="brand-image/"
      />
      <NumberInput source="order" />
    </SimpleForm>
  </Edit>
);
