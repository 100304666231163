import React from 'react';
import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';
import { AmplifyPagination } from '../../ra-aws-amplify';
import ListActions from './ListActions';
import SyncButton from './customField/SyncButton';

export default (props) => (
  <List
    {...props}
    actions={<ListActions showCreate={false} customActions={<SyncButton />} />}
    pagination={<AmplifyPagination />}
    perPage={100}
  >
    <Datagrid rowClick="show">
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="label" />
      <TextField source="type" />
      <TextField source="inputType" />
      <BooleanField source="displayShop" />
      <BooleanField source="active" />
      <BooleanField source="required" />
      <TextField source="order" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
