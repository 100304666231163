import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput,
  required,
} from 'react-admin';
import { S3FileInput } from './aws/S3FileInput';
import { S3ImageInput } from './aws/S3ImageInput';

const transform = (data) => ({
  ...data,
  stageId: data.stageId || '*',
});

export default (props) => (
  <Create {...props} transform={transform}>
    <SimpleForm redirect="show">
      <TextInput source="eventId" disabled validate={[required()]} />
      <TextInput source="name" />
      <TextInput source="summary" />
      <S3ImageInput
        source="image"
        accept="image/*"
        multiple={false}
        keyPrefix="activity-image/"
      />
      <TextInput source="description" />
      <DateTimeInput source="startingAt" />
      <DateTimeInput source="endingAt" />
      <S3FileInput
        source="video"
        accept="video/*"
        multiple={false}
        keyPrefix="activity-video/"
      />
      <S3ImageInput
        source="poster"
        accept="image/*"
        multiple={false}
        keyPrefix="activity-poster/"
      />
    </SimpleForm>
  </Create>
);
