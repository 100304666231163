import React from 'react';
import { Admin, Resource, ListGuesser, ShowGuesser } from 'react-admin';
import {
  AmplifyAuthProvider,
  useAuthProvider,
  useAmplifyDataProvider,
  reducers,
} from '../ra-aws-amplify';
import config from '../aws-exports';
import schema from '../graphql/schema.json';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import LoginPage from './admin/LoginPage';
import OrganizerList from './admin/OrganizerList';
import OrganizerCreate from './admin/OrganizerCreate';
import OrganizerEdit from './admin/OrganizerEdit';
import OrganizerShow from './admin/OrganizerShow';
import EventList from './admin/EventList';
import EventCreate from './admin/EventCreate';
import EventEdit from './admin/EventEdit';
import EventShow from './admin/EventShow';
import BrandList from './admin/BrandList';
import BrandCreate from './admin/BrandCreate';
import BrandEdit from './admin/BrandEdit';
import BrandShow from './admin/BrandShow';
import BoothCreate from './admin/BoothCreate';
import BoothEdit from './admin/BoothEdit';
import BoothShow from './admin/BoothShow';
import BoothList from './admin/BoothList';
import SponsorList from './admin/SponsorList';
import SponsorCreate from './admin/SponsorCreate';
import SponsorEdit from './admin/SponsorEdit';
import SponsorShow from './admin/SponsorShow';
import StageCreate from './admin/StageCreate';
import StageEdit from './admin/StageEdit';
import StageShow from './admin/StageShow';
import StageList from './admin/StageList';
import ShopShow from './admin/ShopShow';
import BoothActivityCreate from './admin/BoothActivityCreate';
import BoothActivityEdit from './admin/BoothActivityEdit';
import ActivityList from './admin/ActivityList';
import ActivityCreate from './admin/ActivityCreate';
import ActivityEdit from './admin/ActivityEdit';
import ActivityShow from './admin/ActivityShow';
// import ParticipantCreate from "./admin/ParticipantCreate";
// import ParticipantEdit from "./admin/ParticipantEdit";
// import ParticipantShow from "./admin/ParticipantShow";
import LiveStreamList from './admin/LiveStreamList';
import LiveStreamCreate from './admin/LiveStreamCreate';
import LiveStreamEdit from './admin/LiveStreamEdit';
import LiveStreamShow from './admin/LiveStreamShow';
// import MessageList from './admin/MessageList';
import MessageEdit from './admin/MessageEdit';
import ShopList from './admin/ShopList';
import ProductList from './admin/ProductList';
import OrderList from './admin/OrderList';
import SubscriberList from './admin/SubscriberList';
import CustomFieldList from './admin/CustomFieldList';

export default () => {
  const authProvider = useAuthProvider();
  const dataProvider = useAmplifyDataProvider({
    config,
    schema,
    queries,
    mutations,
  });

  if (!dataProvider) {
    return <div>Loading</div>;
  }

  return (
    <AmplifyAuthProvider>
      <Admin
        authProvider={
          process.env.NODE_ENV === 'production' ? authProvider : undefined
        }
        dataProvider={dataProvider}
        customReducers={reducers}
        loginPage={LoginPage}
      >
        <Resource
          name="Organizer"
          list={OrganizerList}
          create={OrganizerCreate}
          edit={OrganizerEdit}
          show={OrganizerShow}
        />
        <Resource
          name="Event"
          list={EventList}
          create={EventCreate}
          edit={EventEdit}
          show={EventShow}
        />
        <Resource
          name="Activity"
          list={ActivityList}
          create={ActivityCreate}
          edit={ActivityEdit}
          show={ActivityShow}
        />
        <Resource
          name="BoothActivity"
          // list={ActivityList}
          create={BoothActivityCreate}
          edit={BoothActivityEdit}
          // show={ActivityShow}
        />
        <Resource
          name="Brand"
          list={BrandList}
          create={BrandCreate}
          edit={BrandEdit}
          show={BrandShow}
        />
        <Resource
          name="Booth"
          list={BoothList}
          create={BoothCreate}
          edit={BoothEdit}
          show={BoothShow}
        />
        <Resource
          name="Sponsor"
          list={SponsorList}
          create={SponsorCreate}
          edit={SponsorEdit}
          show={SponsorShow}
        />
        <Resource
          name="Stage"
          list={StageList}
          create={StageCreate}
          edit={StageEdit}
          show={StageShow}
        />
        <Resource
          name="Shop"
          list={ShopList}
          // list={ActivityList}
          // create={ActivityCreate}
          edit={ShopShow}
          show={ShopShow}
        />
        <Resource
          name="Product"
          list={ProductList}
          // list={ActivityList}
          // create={ActivityCreate}
          // edit={ShopShow}
          show={ShowGuesser}
        />
        {/* <Resource
          name="Participant"
          list={ListGuesser}
          create={ParticipantCreate}
          edit={ParticipantEdit}
          show={ParticipantShow}
        /> */}
        <Resource
          name="LiveStream"
          list={LiveStreamList}
          create={LiveStreamCreate}
          edit={LiveStreamEdit}
          show={LiveStreamShow}
        />
        <Resource
          name="Message"
          // list={MessageList}
          // create={LiveStreamCreate}
          edit={MessageEdit}
          // show={LiveStreamShow}
        />
        <Resource
          name="Order"
          list={OrderList}
          // create={LiveStreamCreate}
          // edit={EditGuesser}
          show={ShowGuesser}
        />
        <Resource
          name="Subscriber"
          list={SubscriberList}
          // create={LiveStreamCreate}
          // edit={EditGuesser}
          show={ShowGuesser}
        />
        <Resource
          name="CustomField"
          list={CustomFieldList}
          // create={LiveStreamCreate}
          // edit={EditGuesser}
          show={ShowGuesser}
        />
      </Admin>
    </AmplifyAuthProvider>
  );
};
