import React, { useState } from 'react';
import { Button } from 'react-admin';
import SyncIcon from '@material-ui/icons/Sync';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import API from '@aws-amplify/api';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const apiName = 'SyncApi';
const path = '/sync/customFields';
const myInit = {
  body: {},
  headers: {},
};

const SyncButton = () => {
  const [openInfo, setOpenInfo] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  function handleClick() {
    setOpenInfo(true);
    API.post(apiName, path, myInit)
      .then((response) => {
        // console.log(response);
        setOpenSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCloseInfo() {
    setOpenInfo(false);
  }

  function handleCloseSuccess() {
    setOpenSuccess(false);
  }

  return (
    <>
      <Button label="Sync" onClick={handleClick}>
        <SyncIcon />
      </Button>
      <Snackbar
        open={openInfo}
        autoHideDuration={3000}
        onClose={handleCloseInfo}
      >
        <Alert onClose={handleCloseInfo} severity="info">
          Started syncing custom fields!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          Finished syncing custom fields!
        </Alert>
      </Snackbar>
    </>
  );
};

export default SyncButton;
