import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <TextInput source="description" multiline />
      <TextInput source="email" type="email" />
      <TextInput source="facebookLink" type="url" />
      <TextInput source="instagramLink" type="url" />
      <TextInput source="twitterLink" type="url" />
    </SimpleForm>
  </Create>
);
