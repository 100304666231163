import React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  EditButton,
  ShowButton,
  DeleteButton,
  Button,
} from 'react-admin';
import { Link } from 'react-router-dom';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import { S3FileField } from './aws/S3FileField';
import { S3ImageField } from './aws/S3ImageField';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <NumberField source="order" />
      <S3FileField source="video" />
      <S3ImageField source="poster" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceField
        label="Event"
        source="eventId"
        reference="Event"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <LiveStreamReferenceManyField
        label="LiveStream"
        reference="LiveStream"
        target="listLiveStreamsByStage"
      />
      <ReferenceManyField
        label="Activities"
        reference="Activity"
        target="listActivitiesByStage"
      >
        <Datagrid>
          <TextField source="name" />
          <TextField source="summary" />
          <DateField source="startingAt" showTime />
          <DateField source="endingAt" showTime />
          <EditButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      </ReferenceManyField>
      <AddActivityButton />
      {/* <ConnectActivityButton /> */}
    </SimpleShowLayout>
  </Show>
);

const LiveStreamReferenceManyField = (props) => (
  <ReferenceManyField {...props}>
    <SingleLiveStreamDatagrid record={props.record} />
  </ReferenceManyField>
);

LiveStreamReferenceManyField.defaultProps = {
  label: 'Livestream',
  addLabel: true,
};

const SingleLiveStreamDatagrid = (props) =>
  !!props.ids && !!props.ids.length ? (
    <Datagrid>
      <TextField source="name" />
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  ) : (
    <AddNewLiveStreamButton record={props.record} />
  );

const AddActivityButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/Activity/create',
      state: { record: { stageId: record.id, eventId: record.eventId } },
    }}
    label="Add an activity"
  >
    <CalendarTodayIcon />
  </Button>
);

// const ConnectActivityButton = ({ record }) => (
//   <Button
//     component={Link}
//     to={{
//       pathname: '/BoothActivity/create',
//       state: { record: { stageId: record.id } },
//     }}
//     label="Connect activity"
//   >
//     <CalendarTodayIcon />
//   </Button>
// );

const AddNewLiveStreamButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/LiveStream/create',
      state: { record: { stageId: record.id, name: record.name } },
    }}
    label="Add a live stream"
  >
    <VideoCallIcon />
  </Button>
);
