/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://6vmkwakg5bh73osonbcz5rxg3i.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hjxbeoptxraynpvoebhdqso2h4",
    "aws_cloud_logic_custom": [
        {
            "name": "SyncApi",
            "endpoint": "https://ytbht0tlq1.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:35039863-9859-4c09-847d-4acc30a48149",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_ZPpZ7YlBZ",
    "aws_user_pools_web_client_id": "bacqcf4i3r40aaju4d10ujpuv",
    "oauth": {
        "domain": "pretepop-dev.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://at.pretepop.com/",
        "redirectSignOut": "https://at.pretepop.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_user_files_s3_bucket": "storage105603-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
