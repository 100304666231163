import React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  BooleanField,
  ArrayField,
  EditButton,
  ShowButton,
  DeleteButton,
  Button,
} from 'react-admin';
import { Link } from 'react-router-dom';
import StoreIcon from '@material-ui/icons/Store';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import { S3ImageField } from './aws/S3ImageField';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="details" />
      <S3ImageField source="poster" />
      <S3ImageField source="banner" />
      <DateField source="startingAt" showTime />
      <DateField source="endingAt" showTime />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ArrayField source="themeProperties">
        <Datagrid>
          <TextField source="name" />
          <TextField source="value" />
          <ArrayField source="themeProperties">
            <Datagrid>
              <TextField source="name" />
              <TextField source="value" />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      <ArrayField source="modules">
        <Datagrid>
          <TextField source="name" />
          <BooleanField source="display" />
          <NumberField source="order" />
          <ArrayField source="modules">
            <Datagrid>
              <TextField source="name" />
              <BooleanField source="display" />
              <NumberField source="order" />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      <ReferenceManyField
        label="Brands"
        reference="Brand"
        target="listBrandsByEvent"
      >
        <Datagrid>
          <TextField source="name" />
          <NumberField source="order" />
          <EditButton />
          <ShowButton />
          <DeleteButton />
          <ConditionalAddNewBoothButton />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Booths"
        reference="Booth"
        target="listBoothsByEvent"
      >
        <Datagrid>
          <TextField source="name" />
          <NumberField source="order" />
          <EditButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Activities"
        reference="Activity"
        target="listActivitiesByEvent"
      >
        <Datagrid>
          <TextField source="name" />
          <TextField source="summary" />
          <TextField source="stage.name" label="Stage" />
          <DateField source="startingAt" showTime />
          <DateField source="endingAt" showTime />
          <EditButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Sponsors"
        reference="Sponsor"
        target="listSponsorsByEvent"
      >
        <Datagrid>
          <TextField source="name" />
          <NumberField source="order" />
          <EditButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      </ReferenceManyField>
      {/* <AddNewParticipantButton /> */}
      <AddNewBrandButton />
      <AddNewSponsorButton />
      <AddNewStageButton />
    </SimpleShowLayout>
  </Show>
);

const ConditionalAddNewBoothButton = ({ record, source, ...rest }) =>
  record && record[source] && record[source] !== '*' ? (
    <AddNewBoothButton record={record} source={source} {...rest} />
  ) : null;

const AddNewBoothButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/Booth/create',
      state: {
        record: {
          eventId: record.eventId,
          brandId: record.id,
          name: record.name,
        },
      },
    }}
    label="Add a booth"
  >
    <StoreIcon />
  </Button>
);

const AddNewStageButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/Stage/create',
      state: {
        record: {
          eventId: record.id,
        },
      },
    }}
    label="Add a stage"
  >
    <PresentToAllIcon />
  </Button>
);

const AddNewBrandButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/Brand/create',
      state: {
        record: {
          eventId: record.id,
        },
      },
    }}
    label="Add a brand"
  >
    <StorefrontIcon />
  </Button>
);

const AddNewSponsorButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/Sponsor/create',
      state: {
        record: {
          eventId: record.id,
        },
      },
    }}
    label="Add a sponsor"
  >
    <AttachMoneyIcon />
  </Button>
);
