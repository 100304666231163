import React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ShowButton,
  DeleteButton,
  Button,
} from 'react-admin';
import { Link } from 'react-router-dom';
import StoreIcon from '@material-ui/icons/Store';
import { S3FileField } from './aws/S3FileField';
import { S3ImageField } from './aws/S3ImageField';

export default (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="summary" />
      <S3ImageField source="image" />
      <TextField source="description" />
      <DateField source="startingAt" showTime />
      <DateField source="endingAt" showTime />
      <S3FileField source="video" />
      <S3ImageField source="poster" />
      <ReferenceField
        label="Event"
        source="eventId"
        reference="Event"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Stage"
        source="stageId"
        reference="Stage"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceManyField
        label="Booths"
        reference="Booth"
        target="listBoothsByActivity"
      >
        <Datagrid>
          <TextField source="booth.name" label="Name" />
          <EditButton label="Reconnect" />
          <ShowBoothButton />
          <DeleteButton label="Disconnect" />
        </Datagrid>
      </ReferenceManyField>
      <ConnectBoothButton />
    </SimpleShowLayout>
  </Show>
);

const ShowBoothButton = ({ record, ...rest }) => {
  return <ShowButton {...rest} basePath="/Booth" record={record.booth} />;
};

const ConnectBoothButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/BoothActivity/create',
      state: { record: { activityId: record.id } },
    }}
    label="Connect booth"
  >
    <StoreIcon />
  </Button>
);
