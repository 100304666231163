import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from 'react-admin';

const transform = (data) => ({
  ...data,
  id: data.brandId,
});

export default (props) => (
  <Create {...props} transform={transform}>
    <SimpleForm
      redirect="show"
      initialValues={{
        order: 0,
      }}
    >
      <TextInput source="eventId" disabled validate={[required()]} />
      <TextInput source="brandId" disabled validate={[required()]} />
      <TextInput source="name" />
      <NumberInput source="order" />
    </SimpleForm>
  </Create>
);
