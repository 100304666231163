import React from 'react';
import Box from '@material-ui/core/Box';

const EmbedCodeField = ({ record = {} }) => (
  <Box component="span" display="block">
    {`
      <iframe
        width="560"
        height="315"
        src="${window.location.protocol}//${window.location.host}/livestreams/${record.id}?embed=1"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    `}
    <p>
      <br />
      Query Parameters:
    </p>
    <ul>
      <li>embed - 0 (default, for webpage viewing), 1 (for embedding)</li>
      <li>responsive - 0, 1 (default)</li>
      <li>order (if not responsive) - horizontal (default), vertical</li>
      <li>muted - 0 (default), 1</li>
    </ul>
  </Box>
  // <pre>{`
  //   <iframe
  //     width="560"
  //     height="315"
  //     src="${window.location.protocol}//${window.location.host}/livestreams/${record.id}?embed=1"
  //     frameborder="0"
  //     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
  //     allowfullscreen
  //   ></iframe>
  // `}</pre>
);

EmbedCodeField.defaultProps = { label: 'Embed code', addLabel: true };

export default EmbedCodeField;
