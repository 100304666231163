import React from 'react';
import getHashCode from '../../../helpers/getHashCode';
import getLiveStreamUrl from '../../../helpers/getLiveStreamUrl';

const MasterHostBroadcastLinkField = ({ record = {} }) => (
  <span>
    <a
      href={`${getLiveStreamUrl()}/broadcast/${
        record.id
      }?password=${getHashCode(record.id)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`${getLiveStreamUrl()}/broadcast/${record.id}?password=${getHashCode(
        record.id
      )}`}
    </a>
  </span>
);

MasterHostBroadcastLinkField.defaultProps = {
  label: 'Master host broadcast link',
  addLabel: true,
};

export default MasterHostBroadcastLinkField;