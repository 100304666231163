import React from 'react';
import { Button } from '@material-ui/core';
import FirstPage from '@material-ui/icons/FirstPage';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Toolbar from '@material-ui/core/Toolbar';

import { useSelector } from 'react-redux';

export function AmplifyPagination(props: any) {
  const nextToken = useSelector<any>((state) => state.nextToken);
  if (props.page === 1 && !nextToken) {
    return null;
  }

  return (
    <Toolbar>
      {props.page > 1 && (
        <Button
          color="primary"
          key="prev"
          startIcon={<FirstPage />}
          onClick={() => {
            props.setPage(1);
            props.onNext && props.onNext({});
          }}
        >
          First
        </Button>
      )}
      {nextToken && (
        <Button
          color="primary"
          key="next"
          endIcon={<ChevronRight />}
          onClick={() => {
            props.setPage(props.page + 1);
            props.onNext &&
              props.onNext({
                nextToken,
              });
          }}
        >
          Next
        </Button>
      )}
    </Toolbar>
  );
}

AmplifyPagination.defaultProps = {
  perPage: 100,
};
