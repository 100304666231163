import React from 'react';
import { ImageInput } from 'react-admin';
import { S3Input } from './S3Input';
import { S3ImageField } from './S3ImageField';

export const S3ImageInput = (props) => {
  return (
    <S3Input {...props}>
      <ImageInput>
        <S3ImageField source={props.source} />
      </ImageInput>
    </S3Input>
  );
};
