import React, { useState } from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { AmplifyPagination } from '../../ra-aws-amplify';
import ListActions from './ListActions';

export default (props) => {
  const [filter, setFilter] = useState(null);

  return (
    <List
      {...props}
      actions={<ListActions showCreate={false} />}
      pagination={<AmplifyPagination onNext={(filter) => setFilter(filter)} />}
      filter={filter}
      perPage={100}
    >
      <Datagrid rowClick="show">
        <TextField disabled label="Id" source="id" />
        <TextField source="email" />
        <TextField source="metadata" />
        <TextField source="event.name" label="Event" />
        <DateField source="createdAt" showTime />
      </Datagrid>
    </List>
  );
};
