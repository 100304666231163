import React from 'react';
import { S3Field } from './S3Field';
import { S3Image } from './S3Image';

export const S3ImageField = ({
  // to avoid html img prop errors
  imgProps = {},
  ...props
}) => {
  return (
    <S3Field {...props}>
      <S3Image imgProps={imgProps} />
    </S3Field>
  );
};

S3ImageField.defaultProps = {
  addLabel: true,
};
