import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from 'react-admin';
import { S3FileInput } from './aws/S3FileInput';
import { S3ImageInput } from './aws/S3ImageInput';

export default (props) => (
  <Create {...props}>
    <SimpleForm
      redirect="show"
      initialValues={{
        order: 0,
      }}
    >
      <TextInput source="eventId" disabled validate={[required()]} />
      <TextInput source="name" />
      <NumberInput source="order" />
      <S3FileInput
        source="video"
        accept="video/*"
        multiple={false}
        keyPrefix="stage-video/"
      />
      <S3ImageInput
        source="poster"
        accept="image/*"
        multiple={false}
        keyPrefix="stage-poster/"
      />
    </SimpleForm>
  </Create>
);
