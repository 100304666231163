import React from 'react';
import { List, Datagrid, TextField, EmailField } from 'react-admin';
import { AmplifyPagination } from '../../ra-aws-amplify';
import ListActions from './ListActions';

export default (props) => (
  <List
    {...props}
    actions={<ListActions showCreate={false} />}
    pagination={<AmplifyPagination />}
    perPage={100}
  >
    <Datagrid rowClick="show">
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <EmailField source="email" />
    </Datagrid>
  </List>
);
