import React from 'react';
// import './App.css';
import Admin from './components/Admin';

import Amplify from '@aws-amplify/core';
import config from './aws-exports';
Amplify.configure(config);

function App() {
  return <Admin />;
}

export default App;
