import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  // SelectInput,
} from 'react-admin';
import { S3ImageInput } from './aws/S3ImageInput';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="name" />
      {/* <SelectInput
        source="status"
        choices={[
          { id: 'idle', name: 'Idle' },
          { id: 'active', name: 'Active' },
        ]}
      /> */}
      <S3ImageInput
        source="thumbnail"
        accept="image/*"
        multiple={false}
        keyPrefix="livestream-thumbnail/"
      />
      <TextInput source="activeSpeaker" />
      <NumberInput source="viewsCount" />
      <ReferenceManyField
        label="Messages"
        reference="Message"
        target="listMessagesByLiveStream"
      >
        <Datagrid>
          <TextField source="text" />
          <DateField source="createdAt" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
);
